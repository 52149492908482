import {useEffect} from 'react';
import Head from 'next/head';
import {NextPage} from 'next';
import {serverSideTranslations} from 'next-i18next/serverSideTranslations';
import {useRouter} from 'next/router';
import {observer} from 'mobx-react-lite';
import {useStore} from '@/store/root-store';
import {getLocation} from '@/utils/string';
import {routes} from '@/utils/const';

const Home: NextPage = observer(() => {
  const router = useRouter();
  const {settings: {integration: {gscAccountsConnected}, customer: {profile: {isVendastaClient, hasCompletedRegistration}}}} = useStore('');
  useEffect(() => {
    const prevUrl = localStorage.getItem('nextUrl');
    const checkSignUp = localStorage.getItem('verifyingFromCheckoutPage');
    if (!gscAccountsConnected && isVendastaClient) {
      router.push('/gsc/sites-list');
    } else if (prevUrl && prevUrl?.split('?')?.[0] !== '/') {
      router.push('/home');
    } else if (checkSignUp == 'TRUE') {
      router.push('/order-builder');
      localStorage.removeItem('verifyingFromCheckoutPage');
    } else if (!hasCompletedRegistration) {
      // router.push('/onboarding');
    } else {
      router.push(routes?.home);
    }
  }, [router]);

  return (
    <>
      <Head>
        <title>{getLocation(location)}</title>
      </Head>
    </>
  );
});

export const getStaticProps = async ({locale}) => ({
  props: {
    ...await serverSideTranslations(locale, ['common', 'components', 'home', 'settings', 'gsc', 'pricing_plans']),
  },
});

export default Home;
